import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { AlertController } from '@ionic/angular';
import { StorageService } from '@services/storage.service';
import {UtilsService} from "@services/utils.service";

@Component({
	selector: 'ion-navigation-menu-therapist',
	templateUrl: './navigation-menu-therapist.component.html',
	styleUrls: ['./navigation-menu-therapist.component.scss'],
})
export class NavigationMenuTherapistComponent implements OnInit {
	data: string;
	home: any;
	contents: any;
	hours: any;
	chat: any;
	isMobile: boolean;

	constructor(
		private alertController: AlertController,
		private router: Router,
		private storageService: StorageService,
		private utilsService: UtilsService
	) {
		this.isMobile = this.utilsService.detectMobile();
	}

	ngOnInit() {
		this.router.events.subscribe((event) => {
			if (event instanceof NavigationEnd) {
				// Aquí puedes realizar las acciones que deseas cuando la URL cambia
				this.data = this.storageService.getMenuSelection();

				this.home = document.getElementById('Home');
				this.contents = document.getElementById('Contents');
				this.hours = document.getElementById('Hours');
				this.chat = document.getElementById('Chat');

				if (this.data == 'Home') {
					this.home.style.display = 'block';
					this.contents.style.display = 'none';
					this.hours.style.display = 'none';
					this.chat.style.display = 'none';
				} else if (this.data == 'Contents') {
					this.home.style.display = 'none';
					this.contents.style.display = 'block';
					this.hours.style.display = 'none';
					this.chat.style.display = 'none';
				} else if (this.data == 'Hours') {
					this.home.style.display = 'none';
					this.contents.style.display = 'none';
					this.hours.style.display = 'block';
					this.chat.style.display = 'none';
				} else if (this.data == 'Profile') {
					this.home.style.display = 'block';
					this.contents.style.display = 'none';
					this.hours.style.display = 'none';
					this.chat.style.display = 'none';
				}
				else if (this.data == 'Chat') {
					this.home.style.display = 'none';
					this.contents.style.display = 'none';
					this.hours.style.display = 'none';
					this.chat.style.display = 'block';
				}
			}
		});
	}

	cambiarDisplay1() {
		this.storageService.saveMenuSelection('Home');
		this.data = this.storageService.getMenuSelection();

		if (this.data == 'Home') {
			this.home.style.display = 'block';
			this.contents.style.display = 'none';
			this.hours.style.display = 'none';
			this.chat.style.display = 'none';
		}
	}

	cambiarDisplay2() {
		this.storageService.saveMenuSelection('Contents');
		this.data = this.storageService.getMenuSelection();

		if (this.data == 'Contents') {
			this.home.style.display = 'none';
			this.contents.style.display = 'block';
			this.hours.style.display = 'none';
			this.chat.style.display = 'none';
		}
	}

	cambiarDisplay3() {
		this.storageService.saveMenuSelection('Hours');
		this.data = this.storageService.getMenuSelection();

		if (this.data == 'Hours') {
			this.home.style.display = 'none';
			this.contents.style.display = 'none';
			this.hours.style.display = 'block';
			this.chat.style.display = 'none';
		}
	}

	cambiarDisplay4() {

		if(this.isMobile == true){
			this.storageService.saveMenuSelection('Chat');
			this.data = this.storageService.getMenuSelection();

			if (this.data == 'Chat') {
				this.home.style.display = 'none';
				this.contents.style.display = 'none';
				this.hours.style.display = 'none';
				this.chat.style.display = 'block';
			}
			this.router.navigate(['/therapist/chat']);
		}else{
			this.storageService.saveMenuSelection('Home');
			this.data = this.storageService.getMenuSelection();

			if (this.data == 'Home') {
				this.home.style.display = 'block';
				this.contents.style.display = 'none';
				this.hours.style.display = 'none';
				this.chat.style.display = 'none';
			}
			this.router.navigate(['/therapist/chat-desktop']);
		}
	}

}
